<template>
  <div class="container">
    <header>
      <h1>Cresty V'no</h1>
      <p class="subtitle">Freelance Backend Web2/Web3 Dev</p>
    </header>

    <div class="section card">
      <h2>About Me</h2>
      <p>
        Blockchain in general is something I'm quite passionate about due to
        the impact it has on cross-border transactions and its potential to
        boost financial inclusion; something the traditional banking system
        still struggles with.
      </p>
    </div>

    <div class="section card">
      <h2>Skills Set</h2>
      <div class="skills">
        <span v-for="skill in skills" :key="skill" class="skill">{{ skill }}</span>
      </div>
    </div>

    <div class="section card contact">
      <h2>Connect</h2>
      <div class="contact-links">
        <a href="mailto:hi@crsvno.com">hi@crsvno.com</a>
        <a href="https://x.com/crsvno" target="_blank">@crsvno</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      skills: [
        'Smart Contract Development', 'EVM Chains', 
        'DeFi Protocols', 'IPFS', 'ZKProofs', 'Database', 
        'Distributed Systems', 'Web3.js / ethers.js',  
        'Solidity', 'JS', 'Rust', 'C', 'Go', 'Python'
      ]
    }
  }
}
</script>

<style>
:root {
  --bg-color: #f8f9fa;
  --card-bg: #ffffff;
  --text-color: #333333;
  --accent-color: #3498db;
  --secondary-color: #7f8c8d;
}
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Arial', sans-serif;
  background-color: var(--bg-color);
  color: var(--text-color);
  line-height: 1.6;
}
.container {
  max-width: 700px;
  margin: 0 auto;
  padding: 40px 20px;
}
header {
  text-align: center;
  margin-bottom: 40px;
}
h1 {
  font-size: 2.5rem;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--text-color);
  margin-bottom: 10px;
}
h2 {
  font-size: 1.8rem;
  color: var(--accent-color);
  margin-top: 0;
  margin-bottom: 20px;
}
p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: var(--secondary-color);
}
.subtitle {
  font-size: 1.2rem;
  color: var(--secondary-color);
}
.card {
  background-color: var(--card-bg);
  border-radius: 28px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 50px;
  margin-bottom: 30px;
}
.skills {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.skill {
  background-color: #e7f5ff;
  color: var(--accent-color);
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 0.9rem;
}
.contact {
  text-align: center;
  background-color: var(--accent-color);
  color: #ffffff;
  padding-top: 70px;
  padding-bottom: 70px;
}
.contact h2 {
  color: #ffffff;
}
.contact-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
.contact a {
  color: #ffffff;
  text-decoration: none;
  font-size: 1.1rem;
  transition: opacity 0.3s ease;
}
.contact a:hover {
  opacity: 0.8;
}
@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }
  .subtitle {
    font-size: 1rem;
  }
}
</style>